import React from 'react';
import Loadable from 'react-loadable';

const Dashboard = Loadable({
  loader: () => import('./Dashboard' /* webpackChunkName: 'dashboard' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const DashboardMvoter = Loadable({
  loader: () => import('./DashboardMvoter' /* webpackChunkName: 'dashboard' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const ReportLink = Loadable({
  loader: () => import('./ReportLink' /* webpackChunkName: 'dashboard' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const DashboardWidget = Loadable({
  loader: () => import('./DashboardWidget' /* webpackChunkName: 'dashboard' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const DashboardCallCentre = Loadable({
  loader: () => import('./DashboardCallCentre' /* webpackChunkName: 'dashboard' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const DashboardControl = Loadable({
  loader: () => import('./DashboardControl' /* webpackChunkName: 'dashboard' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

export { Dashboard, DashboardMvoter, DashboardWidget, DashboardCallCentre, DashboardControl, ReportLink };
